@import '~@stitch-fix/mode-style-system';

.container {
  position: fixed;
  top: 50px;
  left: 0;
  z-index: 5;
  width: 100%;
  @include at-breakpoint(860px) {
    top: 58px;
  }
}

.image {
  overflow: hidden;

  img {
    width: 100%;
    height: 375px;
    object-fit: cover;
    object-position: center top;

    @include at-breakpoint($breakpoint-sm) {
      height: calc(
        min(560px, 55vh)
      ); // using calc to get around sass error with min
    }
  }

  @media screen and (max-height: 650px) {
    margin-bottom: 1.5rem;

    img {
      display: none;
    }
  }
}
